#loader {
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 4px;
  z-index: 4;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#loader .spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff9c32;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}