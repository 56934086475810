@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* {
  box-sizing: border-box;
}
html, body {
  background: #1a1a1a url(/static/media/background.1f1b8210.jpeg) no-repeat center;
  background-size: cover; 
  background-repeat: no-repeat;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
header {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
  z-index: 99;
}
header img#logo {
  height: 60px;
  width: auto;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin-top: 60px;
}
@media screen and (max-width: 500px) {
  section.fullWidth {
    width: 100%;
  }
}

section {
  color: #2e2e2e;
  background-color: #ffffff;
  padding: 10px 10px 20px 10px;
  margin: 0 auto 0 auto;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
  max-width: 500px;
}
h2 {
  margin: 0 auto 15px auto;
  padding: 20px;
  border-bottom: 3px solid #ebebeb;

}
h4.numWaiting {
  font-size: 10em;
  font-weight: bold;
  background-color: #e0e0e0;;
  margin: 5px 0;
  padding: 10px;
  border-radius: 3px;
}
a {
  color: #e78823;
  text-decoration: none;
}
a:hover {
  color: #a7261d;
}
section hr {
  border: 2px solid #ebebeb;
  margin-top: 20px;
}

span.closed {
  font-weight: bold;
  color: #920909;
}

span.open {
  font-weight: bold;
  color: #1fa753;
}

footer {
  flex-shrink: 0;
  align-items: center;
  background-color: #1a1a1a;
  color: #969494;
  height: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
}

#guest-form {
  text-align: left;
  margin: 0;
  padding: 0;
}
#guest-form div {
  margin-bottom: 10px;
}
label .req {
  color: #b81a1a;
}
label {
  width: 100%;
  margin-bottom: 6px;
  display: block;
}

#select-location {
  width: 100%;
  height: 50px;
  font-size: 1em;
  margin: 0;
  padding: 0 10px 0 10px;
  border: 1px solid #e7e7e7;
  color: #2e2e2e;
  border-radius: 5px;
  box-sizing: border-box;
}
#select-location {
  margin-top: 8px;
}
#party-size {
  width: 50px;
}

#select-location option {
  margin-bottom: 0 auto;
}

button {
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 8px;
  font-size: 1.25em;
  cursor: pointer;
  margin-top: 5px;
}
button:hover {
  background-color: #5ac25d;
}
button.createid {
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  color: #414141;
}
button.link {
  display: inline;
  width: auto;
  background: none;
  color:#2371e7;
  font-size: 1em;
  margin: 0;
  padding: 0;
}
#loader {
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 4px;
  z-index: 4;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#loader .spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff9c32;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
/* Modal */
#modal {
  position: fixed;
  left: 50%;
  bottom: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  
  transition-duration: 600ms;
  transition-timing-function: ease;
}
#modal.active {
  z-index: 4;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translate(-50%, auto);
          transform: translate(-50%, auto);
}
#modal h3 {
  border-bottom: 1px solid #cacaca;
  padding: 10px;
  margin: 0;
}
#loaderOverlay {
  position: fixed;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px;
  border-radius: 4px;
  z-index: 4;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: none;
}
#loaderOverlay.active {
  display: block;
}
#loaderOverlay .spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #ff9c32;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
#contact-tracing-form, #checkin-form {
  width: 100%;
  text-align: left;
}
input[type='text']:disabled {
  background-color: #d8faf5;
  color: #000000;
}
.form-input {
  margin-bottom: 8px;
}
.notice {
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 8px;
  text-align: left;
}
.yellowbg {
  background-color: #fdff95b9;
}
.bluebg {
  background-color: #dafaf6c2;
}
.notice a {
  color: #a81818;
}
button:disabled {
  background-color: rgb(58, 58, 58);
}
