#contact-tracing-form, #checkin-form {
  width: 100%;
  text-align: left;
}
input[type='text']:disabled {
  background-color: #d8faf5;
  color: #000000;
}
.form-input {
  margin-bottom: 8px;
}
.notice {
  border-radius: 3px;
  padding: 6px;
  margin-bottom: 8px;
  text-align: left;
}
.yellowbg {
  background-color: #fdff95b9;
}
.bluebg {
  background-color: #dafaf6c2;
}
.notice a {
  color: #a81818;
}
button:disabled {
  background-color: rgb(58, 58, 58);
}